import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="TIA Lesson Submission" />
    <h1>Input lesson</h1>
    <form
                  method="post"
                  action="/email-received/"
                  data-netlify="true"
                  name="Lessons"
                  netlify-honeypot="bot-field"
                >
                  <div>
                    <input type="hidden" name="form-name" value="Lessons" />
                  </div>
                  <p style={{ display: `none` }}>
                    <label>
                      Fly trap: <input name="bot-field" />
                    </label>
                  </p>
                  <h2>Lesson Name/Title:</h2>
                  <p><em>Example: How to use the past tense in Saudi Arabic</em></p>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="55"
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Lesson title"
                      required
                    />
                  </div>
                  <h2>Lesson audio:</h2>
                  <p><em>Example: https://drive.google.com/file/d/18tzzzzzzzNwGafQ-5GtgjuqtX2OAOcMs/view?usp=sharing</em></p>
                  <div style={{marginBottom:`1.5rem`}}>
                  <audio controls>
  <source src="/sample.mp3" type="audio/mpeg" />
Your browser does not support the audio element.
</audio>
</div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="55"
                      type="text"
                      name="audio"
                      id="audio"
                      placeholder="Link to lesson audio file (e.g. Google Drive)..."
                      required
                    />
                  </div>
                  <h2>Dialect:</h2>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      type="radio"
                      name="dialect"
                      id="egyptian"
                      value="Egyptian"
                    />
                    <label for="egyptian">Egyptian</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="levantine"
                      value="Levantine"
                    />
                    <label for="levantine">Levantine</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="saudi"
                      value="Saudi"
                    />
                    <label for="saudi">Saudi</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="iraqi"
                      value="Iraqi"
                    />
                    <label for="iraqi">Iraqi</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="sudanese"
                      value="Sudanese"
                    />
                    <label for="sudanese">Sudanese</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="tunisian"
                      value="Tunisian"
                    />
                    <label for="tunisian">Tunisian</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="algerian"
                      value="Algerian"
                    />
                    <label for="algerian">Algerian</label>
                    <input
                      type="radio"
                      name="dialect"
                      id="moroccan"
                      value="Moroccan"
                    />
                    <label for="moroccan">Moroccan</label>
                  </div>
                  <h2>About (lesson description):</h2>
                  <p><em>Example: In this lesson, you'll learn how to form the past tense in the Saudi dialect.</em></p>
                  <div style={{marginBottom: `1rem`}}>
                  <textarea
                      type="text"
                      cols="65"
                      rows="2"
                      name="about"
                      id="about"
                      placeholder="About (one sentence)"
                      required
                    />
                  </div>
                  <h2>Lesson explanation/summary:</h2>
                  <p><em>Example: In Egyptian Arabic nouns are either masculine ‫ مذكر‬or feminine ‫مؤنث‬

Feminine nouns in Arabic usually include:
Most words/ adjectives that have a ‫ ة‬/ ‫ ـة‬at the end of the word.
Most countries/ states/ cities.
Nouns that are feminine by nature.
Some body parts.
Feminine proper names that ends with ‫“ ى‬Alif Maqsoura” and ‫اء‬.
Sometimes it’s pronounced as “t” if it’s in case of “Idafaa”</em></p>
                  <div style={{marginBottom: `1rem`}}>
                  <textarea
                      rows="10"
                      cols="65"
                      name="explain"
                      id="explain"
                      placeholder="Lesson explanation/summary..."
                      required
                    />
                  </div>
                  <h2>Category:</h2>
                  <div style={{marginBottom: `1rem`}}>
                  <input
                      type="checkbox"
                      name="category"
                      id="conversation"
                      value="Conversation"
                    />
                    <label for="conversation">Conversation</label>
                    <input
                      type="checkbox"
                      name="category"
                      id="listening"
                      value="Listening"
                    />
                    <label for="listening">Listening</label>
                    <input
                      type="checkbox"
                      name="category"
                      id="vocabulary"
                      value="Vocabulary"
                    />
                    <label for="vocabulary">Vocabulary</label>
                    <input
                      type="checkbox"
                      name="category"
                      id="grammar"
                      value="Grammar"
                    />
                    <label for="grammar">Grammar</label>
                  </div>
                  <h2>Difficulty:</h2>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      type="radio"
                      name="difficulty"
                      id="beginner"
                      value="Beginner"
                    />
                    <label for="beginner">Beginner</label>
                    <input
                      type="radio"
                      name="difficulty"
                      id="intermediate"
                      value="Intermediate"
                    />
                    <label for="intermediate">Intermediate</label>
                    <input
                      type="radio"
                      name="difficulty"
                      id="advanced"
                      value="Advanced"
                    />
                    <label for="advanced">Advanced</label>
                    </div>

                    <hr />
                    
                  {/**************************************************/}
                  <h2>Dialogues:</h2>
                  <p><em>Example: </em></p>
                  <p><em>The English teacher is sick</em></p>
                  <p><em>‫أستاذ النجليزي عيان‬</em></p>
                  <p><em>ustaz il-ingilizi 3ayan</em></p>
                  <p><em>https://drive.google.com/file/d/18tzzzzzzzNwGafQ-5GtgjuqtX2OAOcMs/view?usp=sharing</em></p>
                  <span>1.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_one_en"
                      id="dialog_one_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_one_arb"
                      id="dialog_one_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_one_trans"
                      id="dialog_one_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_one_audio"
                      id="dialog_one_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      required
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>2.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_two_en"
                      id="dialog_two_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_two_arb"
                      id="dialog_two_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_two_trans"
                      id="dialog_two_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_two_audio"
                      id="dialog_two_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      required
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>3.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_three_en"
                      id="dialog_three_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_three_arb"
                      id="dialog_three_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_three_trans"
                      id="dialog_three_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_three_audio"
                      id="dialog_three_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      required
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>4.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_four_en"
                      id="dialog_four_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_four_arb"
                      id="dialog_four_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_four_trans"
                      id="dialog_four_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_four_audio"
                      id="dialog_four_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      required
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>5.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_five_en"
                      id="dialog_five_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_five_arb"
                      id="dialog_five_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_five_trans"
                      id="dialog_five_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_five_audio"
                      id="dialog_five_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      required
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>6.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_six_en"
                      id="dialog_six_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_six_arb"
                      id="dialog_six_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_six_trans"
                      id="dialog_six_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_six_audio"
                      id="dialog_six_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>7.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_seven_en"
                      id="dialog_seven_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_seven_arb"
                      id="dialog_seven_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_seven_trans"
                      id="dialog_seven_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_seven_audio"
                      id="dialog_seven_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>8.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_eight_en"
                      id="dialog_eight_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_eight_arb"
                      id="dialog_eight_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_eight_trans"
                      id="dialog_eight_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_eight_audio"
                      id="dialog_eight_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>9.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_nine_en"
                      id="dialog_nine_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_nine_arb"
                      id="dialog_nine_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_nine_trans"
                      id="dialog_nine_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_nine_audio"
                      id="dialog_nine_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      
                    />
                  </div>
                  {/**************************************************/}
                  <hr />
                  {/**************************************************/}
                  <span>10.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_ten_en"
                      id="dialog_ten_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      dir="rtl"
                      type="text"
                      name="dialog_ten_arb"
                      id="dialog_ten_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="75"
                      type="text"
                      name="dialog_ten_trans"
                      id="dialog_ten_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    
                    <input
                      size="75"
                      type="text"
                      name="dialog_ten_audio"
                      id="dialog_ten_audio"
                      placeholder="Link to audio file (e.g. Google Drive)"
                      
                    />
                  </div>
                  {/**************************************************/}
                  <h2>Vocabulary:</h2>
                  <p><em>Example:</em></p>
                  <p><em>house</em></p>
                  <p><em>بيت</em></p>
                  <p><em>bait</em></p>
                  <p><em>https://drive.google.com/file/d/18tzzzzzzzNwGafQ-5GtgjuqtX2OAOcMs/view?usp=sharing</em></p>
                  <span>1.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_one_en"
                      id="vocab_one_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_one_arb"
                      id="vocab_one_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_one_trans"
                      id="vocab_one_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_one_audio"
                      id="vocab_one_audio"
                      placeholder="Audio"
                      required
                    />
                  </div>

                  <span>2.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_two_en"
                      id="vocab_two_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_two_arb"
                      id="vocab_two_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_two_trans"
                      id="vocab_two_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_two_audio"
                      id="vocab_two_audio"
                      placeholder="Audio"
                      required
                    />
                  </div>

                  <span>3.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_three_en"
                      id="vocab_three_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_three_arb"
                      id="vocab_three_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_three_trans"
                      id="vocab_three_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_three_audio"
                      id="vocab_three_audio"
                      placeholder="Audio"
                      required
                    />
                  </div>

                  <span>4.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_four_en"
                      id="vocab_four_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_four_arb"
                      id="vocab_four_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_four_trans"
                      id="vocab_four_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_four_audio"
                      id="vocab_four_audio"
                      placeholder="Audio"
                      required
                    />
                  </div>

                  <span>5.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_five_en"
                      id="vocab_five_en"
                      placeholder="English"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_five_arb"
                      id="vocab_five_arb"
                      placeholder="عربي"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_five_trans"
                      id="vocab_five_trans"
                      placeholder="Transliteration"
                      required
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_five_audio"
                      id="vocab_five_audio"
                      placeholder="Audio"
                      required
                    />
                  </div>
                  <span>6.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_six_en"
                      id="vocab_six_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_six_arb"
                      id="vocab_six_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_six_trans"
                      id="vocab_six_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_six_audio"
                      id="vocab_six_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>7.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_seven_en"
                      id="vocab_seven_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_seven_arb"
                      id="vocab_seven_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_seven_trans"
                      id="vocab_seven_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_seven_audio"
                      id="vocab_seven_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>8.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_eight_en"
                      id="vocab_eight_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_eight_arb"
                      id="vocab_eight_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_eight_trans"
                      id="vocab_eight_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_eight_audio"
                      id="vocab_eight_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>9.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_nine_en"
                      id="vocab_nine_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_nine_arb"
                      id="vocab_nine_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_nine_trans"
                      id="vocab_nine_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_nine_audio"
                      id="vocab_nine_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>10.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_ten_en"
                      id="vocab_ten_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_ten_arb"
                      id="vocab_ten_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_ten_trans"
                      id="vocab_ten_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_ten_audio"
                      id="vocab_ten_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>11.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_eleven_en"
                      id="vocab_eleven_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_eleven_arb"
                      id="vocab_eleven_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_eleven_trans"
                      id="vocab_eleven_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_eleven_audio"
                      id="vocab_eleven_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>12.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_twelve_en"
                      id="vocab_twelve_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_twelve_arb"
                      id="vocab_twelve_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_twelve_trans"
                      id="vocab_twelve_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_twelve_audio"
                      id="vocab_twelve_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>13.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_13_en"
                      id="vocab_13_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_13_arb"
                      id="vocab_13_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_13_trans"
                      id="vocab_13_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_13_audio"
                      id="vocab_13_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>14.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_14_en"
                      id="vocab_14_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_14_arb"
                      id="vocab_14_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_14_trans"
                      id="vocab_one_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_14_audio"
                      id="vocab_14_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>15.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_15_en"
                      id="vocab_15_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_15_arb"
                      id="vocab_15_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_15_trans"
                      id="vocab_15_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_15_audio"
                      id="vocab_15_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>16.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_16_en"
                      id="vocab_16_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_16_arb"
                      id="vocab_16_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_16_trans"
                      id="vocab_16_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_16_audio"
                      id="vocab_16_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>17.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_17_en"
                      id="vocab_17_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_17_arb"
                      id="vocab_17_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_17_trans"
                      id="vocab_17_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_17_audio"
                      id="vocab_17_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>18.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_18_en"
                      id="vocab_18_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_18_arb"
                      id="vocab_18_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_18_trans"
                      id="vocab_18_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_18_audio"
                      id="vocab_18_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>19.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_19_en"
                      id="vocab_19_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_19_arb"
                      id="vocab_19_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_19_trans"
                      id="vocab_19_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_19_audio"
                      id="vocab_19_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  <span>20.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_20_en"
                      id="vocab_20_en"
                      placeholder="English"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      dir="rtl"
                      name="vocab_20_arb"
                      id="vocab_20_arb"
                      placeholder="عربي"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_20_trans"
                      id="vocab_20_trans"
                      placeholder="Transliteration"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="vocab_20_audio"
                      id="vocab_20_audio"
                      placeholder="Audio"
                      
                    />
                  </div>
                  {/**************************************************/}
                  <h2>Quiz:</h2>
                  <p><em>Example:</em></p>
                  <p><em>ﺧﻤﺲ أﻻف ﺗﻤﺎﻧﻴﺔ و ﻋﺸﺮﻳﻦ‬</em></p>
                  <p><em>5028</em></p>
                  <p><em>1128</em></p>
                  <p><em>8637</em></p>
                  <p><em>4528</em></p>
                  <p><em>Answer: 5028</em></p>
                  <span>1.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_one_question"
                      id="vocab_one_question"
                      placeholder="Question... (Arabic or English)"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_one_option_one"
                      id="vocab_one_option_one"
                      placeholder="Option 1"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_one_option_two"
                      id="vocab_one_option_two"
                      placeholder="Option 2"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_one_option_three"
                      id="vocab_one_option_three"
                      placeholder="Option 3"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_one_option_four"
                      id="vocab_one_option_four"
                      placeholder="Option 4"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_one_answer"
                      id="vocab_one_answer"
                      placeholder="Answer"
                      
                    />
                  </div>
                  <span>2.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_two_question"
                      id="vocab_two_question"
                      placeholder="Question... (Arabic or English)"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_two_option_one"
                      id="vocab_two_option_one"
                      placeholder="Option 1"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_two_option_two"
                      id="vocab_two_option_two"
                      placeholder="Option 2"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_two_option_three"
                      id="vocab_two_option_three"
                      placeholder="Option 3"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_two_option_four"
                      id="vocab_two_option_four"
                      placeholder="Option 4"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_two_answer"
                      id="vocab_two_answer"
                      placeholder="Answer"
                      
                    />
                  </div>
                  <span>3.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_three_question"
                      id="vocab_three_question"
                      placeholder="Question... (Arabic or English)"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_three_option_one"
                      id="vocab_three_option_one"
                      placeholder="Option 1"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_three_option_two"
                      id="vocab_three_option_two"
                      placeholder="Option 2"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_three_option_three"
                      id="vocab_three_option_three"
                      placeholder="Option 3"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_three_option_four"
                      id="vocab_three_option_four"
                      placeholder="Option 4"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_three_answer"
                      id="vocab_three_answer"
                      placeholder="Answer"
                      
                    />
                  </div>
                  <span>4.</span>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_four_question"
                      id="vocab_four_question"
                      placeholder="Question... (Arabic or English)"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_four_option_one"
                      id="vocab_four_option_one"
                      placeholder="Option 1"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_four_option_two"
                      id="vocab_four_option_two"
                      placeholder="Option 2"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_four_option_three"
                      id="vocab_four_option_three"
                      placeholder="Option 3"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_four_option_four"
                      id="vocab_four_option_four"
                      placeholder="Option 4"
                      
                    />
                  </div>
                  <div style={{marginBottom: `1rem`}}>
                    <input
                      size="35"
                      type="text"
                      name="quiz_four_answer"
                      id="vocab_four_answer"
                      placeholder="Answer"
                      
                    />
                  </div>

                  <div style={{ textAlign: `center`, marginBottom: `1rem` }}>
                    <button
                      type="submit"
                      style={{ maxWidth: `50%`, margin: `auto` }}
                      value="Send Message"
                    >
                      Submit
                      </button>
                  </div>
                </form>
  </Layout>
)

export default IndexPage
